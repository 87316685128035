body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scroll-to-expore {
  position: absolute !important;
  bottom: calc(10% - 10px) !important;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff !important;
}

.banner {
  z-index: 1000;
max-width: initial;
height: calc(100vh - 104px);
background-size: cover;
background-position: 50%;
margin: 0 auto;
position: relative;
overflow: hidden;
background-repeat: no-repeat;
}
#video {
  /*
  z-index: -1;
  width: 100%;
  max-width: 100%;
  */
  
 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  overflow: hidden;
}
.galleryVideo {
min-width: 100%;
min-height: 100%;
width: auto;
height: auto;
overflow: hidden;
display: block;
}

.img {
  width: 100vw;
}

.about {
  background-image: url("assets/about.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.contact {
  background-image: url("assets/113-14.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}



